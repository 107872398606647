input.ess-input {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border-bottom-color: var(--gray-100);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  box-shadow: none;
  color: var(--primary-text-color);
  font-size: 13px;
  letter-spacing: 1.54px;
  line-height: 13px;
  margin-bottom: var(--spacing-2\.5);
  margin-top: 3rem;
  padding-bottom: var(--spacing-3);
  width: 100%;

  &::placeholder {
    color: var(--secondary-text-color);
    text-transform: uppercase;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px (var(--white)) inset !important;
  }
}
