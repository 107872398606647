@use 'abstracts/media-queries' as mq;
@use 'abstracts/variables' as variables;

* {
  box-sizing: border-box;
}

html,
body {
  box-sizing: border-box;
  color: var(--primary-text-color);
  display: block;
  font-size: 16px;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  margin: 0;
  overflow: visible;
  position: relative;
  width: 100%;

  &.loading {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
}

.spinner {
  bottom: 0;
  height: 40px;
  left: 0;
  margin: auto auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 40px;
}

.full-width {
  width: 100% !important;
}

.text-center {
  text-align: center !important;
}

.slick-dots button {
  min-width: 0 !important;
  padding: 0 !important;
}

// Mat-select options styles
.mat-option-text {
  font-family: var(--primary-font-family) !important;
  font-size: 1.5rem;
  font-weight: 400;
}

// SLIDESHOW Image Gallery
.slideshow-container {
  max-height: 50rem;
  transition: background 1s linear;

  .arrow-container::before {
    display: none !important;
  }

  .slick-dots li button::before {
    height: 0.6rem !important;
    width: 0.6rem !important;
  }
}

.mw-container {
  margin: 0 auto;
  padding-left: variables.$container-padding;
  padding-right: variables.$container-padding;
  width: 100%;

  @media screen and (min-width:#{mq.$mq-sm}) {
    max-width: 100%;
    padding-left: variables.$tablet-container-padding;
    padding-right: variables.$tablet-container-padding;
  }

  @media screen and (min-width:#{mq.$mq-xl}) {
    max-width: calc(87.5rem + 2 * variables.$container-padding);
    padding-left: variables.$container-padding;
    padding-right: variables.$container-padding;
  }
}

.muted {
  color: var(--gray-150);
}

.white {
  color: var(--white) !important;
}

a {
  all: unset;
  cursor: pointer;
  font-family: var(--secondary-font-family) !important;
  font-size: var(--font-s);

  @media screen and (min-width:#{mq.$mq-sm}) {
    font-size: var(--font-normal);
  }
}

// TODO: move to use in comoponents
.wrapper {
  margin: 0 auto;
  max-width: 1280px;
  padding: 0 20px;
  width: 100%;
}

.day-date:first-letter {
  text-transform: capitalize;
}
