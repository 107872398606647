@use '@angular/material' as mat;
@use 'abstracts/media-queries' as mq;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$essentialist-primary: mat.m2-define-palette(mat.$m2-grey-palette);
$essentialist-accent: mat.m2-define-palette(mat.$m2-blue-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$essentialist-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$essentialist-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $essentialist-primary,
      accent: $essentialist-accent,
      warn: $essentialist-warn,
    ),
    typography: mat.m2-define-typography-config(),
    density: 0,
  )
);

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.m2-define-typography-config(
  $font-family: var(--primary-font-family),
  $headline-5: mat.m2-define-typography-level(32px, 32px, 700),
  $body-2: mat.m2-define-typography-level(16px, 16px, 500),
  $subtitle-2: mat.m2-define-typography-level(18px, 18px, 500),
  $button: mat.m2-define-typography-level(16px, 36px, 500),
  $headline-1: mat.m2-define-typography-level(68px, 68px, 400),
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($essentialist-theme);

/*** mat-dropdown **/
.mat-select-panel .mat-optgroup-label,
.mat-select-panel .mat-option .mat-option-text {
  font-size: mat.m2-font-size($custom-typography, body-1);
}

label.mdc-floating-label {
  font-family: var(--primary-font-family);

  mat-label {
    font-size: var(--font-base);
  }
}

.mat-mdc-form-field-infix {
  height: auto;
}

.mat-mdc-option {
  font-family: var(--primary-font-family);
}

.ess-snackbar {
  .mdc-snackbar__surface {
    background-color: #303030 !important;
    padding: 0 !important;

    .mat-mdc-snack-bar-label {
      .mdc-snackbar__label {
        color: #ffffff !important;
        font-family: var(--secondary-font-family) !important;
        font-size: var(--font-normal);
        font-weight: normal;
        line-height: var(--leading-snug);
        margin: var(--spacing-1) 0 0;
        padding: 1rem 1.5rem 1.25rem !important;
        white-space: pre-wrap;
      }

      @media only screen and (min-width: #{mq.$mq-sm}) {
        .mdc-snackbar__label {
          margin: var(--spacing-1) 0 0;
        }
      }
    }
  }
}
.mat-mdc-tooltip-panel {
  .mat-mdc-tooltip {
    .mdc-tooltip__surface {
      background: var(--primary-text-color);
      color: white;
      font-family: var(--secondary-font-family) !important;
      font-size: var(--font-normal);
      text-align: center;
    }
  }
}

:root {
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.54);
  --mdc-filled-text-field-focus-label-text-color: rgba(0, 0, 0, 0.54);
}
