@use 'abstracts/media-queries' as mq;

@mixin sans {
  font-family: var(--secondary-font-family) !important; // UniversLTStd
  font-size: var(--font-normal); //0.9375rem / 15px
  line-height: var(--leading-snug); //1.375rem / 22 px
  & > small {
    font-size: var(--font-s);
    line-height: var(--leading-5);
  }
  @media only screen and (min-width: #{mq.$mq-sm}) {
    font-size: 1.0625rem;
    line-height: var(--leading-6);
    margin: var(--spacing-1) 0 0;
    & > small {
      font-size: var(--font-normal);
      line-height: var(--leading-snug);
    }
  }
}

.condensed,
.condensed * {
  font-family: var(--primary-bold-condensed-font-family) !important;
  letter-spacing: var(--letter-spacing-small-font-tight);
}

// h1
.heading-title,
.itinerary-title {
  font-family: var(--serif-headings) !important;
  font-size: 3.125rem;
  letter-spacing: 0.094rem;
  line-height: 3.125rem;
  margin: 0;
  text-transform: uppercase;
}

.heading- {
  &xl,
  &l,
  &m,
  &s {
    color: var(--primary-text-color);
    font-weight: normal;
    padding: 0;
  }
  // heading-xl => h2
  &xl {
    font-family: var(--serif-headings) !important; // LouizeDisplay
    font-size: var(--font-l-2); //1.625rem / 26px
    letter-spacing: 0.094rem; // 1.5px
    line-height: var(--font-xl); //  1.875rem; / 30px
    margin: 0;
    text-transform: uppercase;

    &.condensed {
      font-family: var(--primary-bold-condensed-font-family) !important;
    }
  }

  // heading-l => h4
  &l {
    font-family: var(--primary-font-family) !important; // PlantinMTPro
    font-size: var(--font-l); // 1.25rem / 20px
    line-height: var(--leading-snug); // 1.375rem / 22 px
  }

  // heading-m => h5
  &m {
    @include sans;
    margin: 0;
  }

  // heading-s => h3
  &s {
    font-family: var(--secondary-font-family) !important; // UniversLTStd
    font-size: var(--font-xxs); // 0.625rem / 10px
    letter-spacing: var(--letter-spacing-medium); // 0.086rem / 1.38px
    line-height: var(--leading-snug); // 1.375rem / 22 px
    margin: 0.375rem 0 0.125rem;
    text-transform: uppercase;

    strong {
      font-family: var(--secondary-font-family-bold); // UniversLTStd-Bold
      font-weight: normal;
      text-transform: uppercase;
    }
  }
}

p {
  color: var(--primary-text-color);
  font-family: var(--primary-font-family) !important;
  font-size: var(--font-base);
  font-weight: normal;
  line-height: var(--leading-normal);
  margin: var(--spacing-1) 0 0;
  padding: 0;

  &.sans {
    @include sans;
  }
}

@media only screen and (min-width: #{mq.$mq-sm}) {
  // h1
  .heading-title,
  .itinerary-title {
    font-size: var(--font-4xl); //4rem / 64px
    line-height: var(--font-4xl); //4rem / 64px
  }

  // h2
  .heading-xl {
    font-size: 2rem;
    line-height: var(--leading-9);
    margin: var(--spacing-2) 0 var(--spacing-1);

    &.condensed {
      font-size: 2rem;
      line-height: var(--leading-9);
    }
  }

  // h4
  .heading-l {
    font-size: var(--font-l-0);
    line-height: var(--leading-7\.5);
    margin: 0.375rem 0 0;
  }

  // h5
  .heading-m {
    line-height: var(--leading-snug);
  }

  // h3
  .heading-s {
    font-size: var(--font-s);
    letter-spacing: var(--letter-spacing-large);
    line-height: var(--leading-snug);
    margin: var(--spacing-1) 0 0.125rem;

    strong {
      font-size: var(--font-s);
      letter-spacing: var(--letter-spacing-large);
    }
  }

  p {
    font-size: var(--font-m);
    line-height: var(--leading-6);
    margin: var(--spacing-1) 0 0;

    &.error-msg {
      color: var(--red);
      margin-top: 1rem;
      text-align: left;
    }
  }
}

@media only screen and (min-width: #{mq.$mq-xl}) {
  .itinerary-title {
    font-size: var(--font-5xl); // 5.625rem / 90px
    line-height: var(--font-5xl); // 5.625rem / 90px
    margin: var(--spacing-4) 0 0;
  }
  .heading-title {
    font-size: 5rem; // 80px
    line-height: 5rem; // 80px
    margin: var(--spacing-4) 0 0;
  }
}
