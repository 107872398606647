:root {
  // #region Backgrounds
  --primary-background-color: #ffffff;
  --secondary-background-color: #c2c2c2;
  --highlight-background-color: #e5e5e5; // active bg
  --disabled-background-color: #8d8d8d; // inactive bg
  // #endregion

  // #region Borders
  --primary-border-color: #4d4d4d; // ::active, ::focus
  --secondary-border-color: #8d8d8d; // inactive
  --terciary-border-color: #b3b3b3; // inactive
  --error-border-color: #c60000;
  // #endregion

  // #region Swiper
  --swiper-pagination-color: white;
  --swiper-navigation-color: white;
  // #endregion

  // #region Texts
  --primary-text-color: #303030;
  --secondary-text-color: #4a4a4a;
  --terciary-text-color: #707070;
  --light-text-color: #fff;
  --logo-color: #666666;
  --error-text-color: #c60000;
  --error: #f44336;
  // #endregion

  --white: #fff;
  --success: #7bc77b;
  --amex: #0160b6;
  --link-blue: #39f;
  --focus: #007aff;
  --black: #000000;
  --benefits: #b9d2db;
  --text-black: #4a4a4a;
  --border-grey: #d5d5d5;
  --light-grey: #d5d5d5;
  --med-grey: #e5e5e5;
  --lighter-grey: #f3f2f2;
  --dark-grey: #9b9b9b;
  --gray: #aaaaaa;
  --gray-25: #acacac;
  --gray-50: #f4f4f4;
  --gray-100: #d5d5d5;
  --gray-150: #303030;
  --green: #6d9d54;
  --red: #cf2a26;
  --stone: #f0efea;
  --yellow: #f1c44b;
  --sand: #f2f1ee;
  --sand-25: #dfdcd4;
  --sand-50: #f9f8f6;
  --insiders: #d5e7e8;
  --itinerary-success: #81c060;
  --itinerary-warning: #ffbd4a;
  --itinerary-danger: #db0a0a;
  --self-explore: #c1cbc1;
  --trip-planning: rgba(232, 174, 59, 0.9);
  --trip-ready: rgba(118, 180, 101, 0.9);
  --trip-building: rgba(0, 0, 0, 0.2);
}
