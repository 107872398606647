.ess-btn {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 1px solid var(--primary-border-color);
  color: var(--primary-text-color);
  cursor: pointer;
  font-family: var(--secondary-font-family);
  font-size: var(--font-sm);
  letter-spacing: normal;
  line-height: var(--spacing-4);
  min-width: 8.75rem !important;
  padding: 0.688rem 1.25rem 0.563rem;
  transition: background-color ease-in-out 300ms;

  &:disabled {
    border-color: var(--gray-100);
    color: var(--terciary-text-color);
    pointer-events: none;
  }

  &:not(:disabled):focus,
  &:not(:disabled):hover {
    border-color: var(--gray);
    color: var(--terciary-text-color);

    //border-color: black;
    //background-color: rgb(240, 240, 240);
    &.border-none {
      border: none;
    }

    i.icon svg {
      fill: var(--gray);
    }
  }

  > span {
    font-family: var(--secondary-font-family);
  }

  &:not(.full-width) {
    min-width: 255px;
  }

  &.full-width {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
  }

  &.btn-link {
    background-color: transparent;
    border-color: transparent;
    margin: 0;
    min-width: auto;

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      border-color: transparent;
    }
  }

  // bg color
  &.btn-transparent {
    background-color: transparent;
  }

  &.btn-inverted {
    background-color: black;
    border-color: black;
    color: white;
  }

  &.btn-large {
    line-height: 52px;
    max-height: inherit;
  }

  &.white {
    border-color: white;
    color: white;

    &:not(:disabled):focus,
    &:not(:disabled):hover {
      background-color: rgba(255, 255, 255, 0.2);
      border-color: white;
      color: white;
    }

    &:disabled {
      background-color: #5f5f5f;
      color: #5f5f5f;
    }
  }

  i.icon {
    margin: 0 var(--spacing-2);

    svg {
      fill: var(--primary-text-color);
      height: 0.875rem;
      transform: translateY(2px);
    }
  }
}

// TODO: unify the styles for the buttons in travel
.ess-button {
  background-color: var(--highlight-background-color);
  border-color: var(--highlight-background-color);
  color: var(--primary-text-color);
  font-family: var(--secondary-font-family);
  font-size: var(--font-normal);
  line-height: 1;
  min-height: 37px;
  opacity: 0.75;
  text-transform: uppercase;
  transition: all 200ms linear;

  &:not(.p-button-icon-only) {
    padding-top: 11px;
  }

  &.font-primary {
    font-family: var(--primary-font-family);
  }

  &.inverted,
  &.inverted:-webkit-any-link {
    color: var(--white);
  }

  /** Status */
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }

  &.no-hover:focus,
  &.no-hover:hover {
    background-color: transparent;
    border-color: transparent;
    color: transparent;
  }

  &:not(:disabled):not(.no-hover):focus,
  &:not(:disabled):not(.no-hover):hover {
    background-color: var(--highlight-background-color);
    border-color: var(--highlight-background-color);
    color: var(--primary-text-color);
    opacity: 1;

    &.border-none {
      border: none;
    }
  }

  /** Weight */
  &.bold,
  &.bold::ng-deep span {
    font-family: var(--secondary-font-family-bold);
  }

  /* Sizes */
  &.big {
    height: 55px;
    width: 240px;
  }

  /****** Variations */
  &.link {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font-size: var(--font-xxs);
    letter-spacing: 0.098rem;
    min-height: auto;
    padding: 0;
    text-decoration: none;
    width: auto;

    i {
      font-size: var(--font-xxxs);
      padding-right: var(--spacing-2);
    }
  }

  &.link:not(:disabled):focus,
  &.link:not(:disabled):hover {
    background-color: transparent;
  }

  &.pretty {
    background-color: transparent;
    border: none;
    color: var(--secondary-text-color);
    font-size: var(--font-m);
    min-height: 0;
    opacity: 1;
    padding: 0 0 0 1px;
    text-transform: none;

    &.inverted {
      color: var(--white);
    }
  }

  &.pretty:not(:disabled).is-active,
  &.pretty:not(:disabled):focus,
  &.pretty:not(:disabled):hover {
    background-color: transparent;
    color: var(--primary-text-color);

    &.inverted {
      color: var(--white);
    }
  }

  &.form-submit {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: var(--white);
    border: 1px solid var(--gray-100);
    color: var(--primary-text-color);
    cursor: pointer;
    font-size: var(--font-sm);
    height: 50px;
    opacity: 1;
    padding: 1px 0;
    text-transform: uppercase;
    width: 255px;

    &:disabled {
      background-color: var(--white);
      color: var(--terciary-text-color);
      opacity: 1;
      pointer-events: none;
    }
  }

  /* --- */
  &.bg-transparent,
  &.bg-transparent:focus,
  &.bg-transparent:hover {
    background-color: transparent;
    border-color: var(--primary-text-color);
    color: var(--primary-text-color);
    opacity: 1;

    &.inverted {
      border-color: var(--white);
      color: var(--white);
    }

    &.border-none {
      border: none;
    }
  }

  &.bg-transparent:not(:disabled):not(.no-hover):focus,
  &.bg-transparent:not(:disabled):not(.no-hover):hover {
    background-color: var(--highlight-background-color);
    border-color: var(--highlight-background-color);
    color: var(--secondary-text-color);
  }
}

.button-content {
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  font: inherit;
  outline: inherit;
  padding: 0;
  text-align: inherit;

  &:disabled {
    cursor: not-allowed;
  }
}
