.mat-mdc-dialog-container .mdc-dialog__surface {
  background: transparent !important;
  border-radius: 2px;
  box-shadow: none;
  padding: 0;

  /* temporary fix
    Safari does not behave properly and the overlay takes over
    the content and the background color is not properly applied
    */
  > * {
    display: block;
  }

  @media screen and (max-width: 1024px) {
    background: white;
  }

  /* end fix */

  .modal-backdrop {
    background-color: rgba(255, 255, 255, 0.1);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  .modal-container {
    background-color: white;
    height: auto;
    margin: 13vh auto;
    max-width: 1024px;
    overflow-y: auto;
    padding: 5rem;
    position: relative;
    width: 100%;

    @media screen and (max-width: 1024px) {
      height: 100%;
      margin: 0 auto;
    }

    .close {
      background: none;
      border: none;
      color: var(--primary-text-color);
      cursor: pointer;
      position: absolute;
      right: var(--spacing-4);
      top: var(--spacing-4);

      .mat-icon {
        font-size: var(--font-l-1);
        height: var(--leading-6);
        width: var(--leading-6);
      }
    }
  }

  button.full-width {
    bottom: 0;
    left: 0;
    padding: 1rem;
    position: absolute;
  }
}

@media (max-width: 1024px) {
  ::ng-deep mat-dialog-container.mat-mdc-dialog-container {
    padding: var(--spacing-4) 0;

    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }

  .mat-mdc-dialog-container .mdc-dialog__surface {
    .modal-container {
      min-height: 100vh;
      min-height: 100dvh;
      padding: 1rem;
    }
  }
}

.cdk-global-scrollblock {
  overflow: hidden;
}

.modal-container-surface {
  height: 100vh;
  height: 100dvh;
  overflow: auto;
  width: 100%;
}

.modal-title .title {
  font-family: var(--secondary-font-family) !important;
  font-size: var(--font-l-2);
  font-weight: normal;
  line-height: var(--leading-7\.5);
  margin: 0;
  margin-bottom: var(--spacing-2\.5);
}
