swiper {
  width: 100%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
  text-align: center;

  &.swiper-navigation-opaque {
    .swiper-button-next,
    .swiper-button-prev {
      background: white;
      height: 100%;
      padding: 0 10px;
      top: 0;
      z-index: 1000;
      opacity: 1;
      pointer-events: inherit;
      &.swiper-button-disabled::after {
        opacity: 0.35;
      }
    }

    .swiper-button-prev {
      left: 0;
    }

    .swiper-button-next {
      right: 0;
    }
  }
}
