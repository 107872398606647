@use 'abstracts/mixins' as *;
$font-path: '../../assets/fonts/';

@font-face {
  font-display: block;
  font-family: 'EssenThiUltCn';
  font-style: normal;
  font-weight: 400;
  src:
    url('../../assets/fonts/EssenThiUltCn.woff') format('woff'),
    url('../../assets/fonts/EssenThiUltCn.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd;
  font-style: normal;
  font-weight: 400;
  fontd-display: swap;
  src:
    url('../../assets/fonts/UniversLTStd.woff') format('woff'),
    url('../../assets/fonts/UniversLTStd.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-Cn;
  font-style: normal;
  font-weight: 400;
  fontd-display: swap;
  src:
    url('../../assets/fonts/UniversLTStd-Cn.woff') format('woff'),
    url('../../assets/fonts/UniversLTStd-Cn.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-Bold;
  font-style: normal;
  font-weight: 700;
  fontd-display: swap;
  src:
    url('../../assets/fonts/UniversLTStd-Bold.woff') format('woff'),
    url('../../assets/fonts/UniversLTStd-Bold.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-ThinUltraCn;
  font-style: normal;
  font-weight: 100;
  fontd-display: swap;
  src:
    url('../../assets/fonts/UniversLTStd-ThinUltraCn.woff') format('woff'),
    url('../../assets/fonts/UniversLTStd-ThinUltraCn.woff2') format('woff2');
}
@font-face {
  font-family: UniversLTStd-UltraCn;
  font-style: normal;
  font-weight: 400;
  fontd-display: swap;
  src:
    url('../../assets/fonts/UniversLTStd-UltraCn.woff') format('woff'),
    url('../../assets/fonts/UniversLTStd-UltraCn.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro-Light;
  font-style: normal;
  font-weight: 300;
  fontd-display: swap;
  src:
    url('../../assets/fonts/PlantinMTPro-Light.woff') format('woff'),
    url('../../assets/fonts/PlantinMTPro-Light.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro-LightItalic;
  font-style: normal;
  font-weight: normal;
  fontd-display: swap;
  src:
    url('../../assets/fonts/PlantinMTPro-LightItalic.woff') format('woff'),
    url('../../assets/fonts/PlantinMTPro-LightItalic.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro;
  font-style: normal;
  font-weight: 400;
  fontd-display: swap;
  src:
    url('../../assets/fonts/PlantinMTPro-Regular.woff') format('woff'),
    url('../../assets/fonts/PlantinMTPro-Regular.woff2') format('woff2');
}
@font-face {
  font-family: PlantinMTPro-Bold;
  font-style: normal;
  font-weight: 700;
  fontd-display: swap;
  src:
    url('../../assets/fonts/PlantinMTPro-Bold.woff') format('woff'),
    url('../../assets/fonts/PlantinMTPro-Bold.woff2') format('woff2');
}

@font-face {
  font-family: PlantinMTPro-BoldCn;
  font-style: normal;
  font-weight: 700;
  fontd-display: swap;
  src:
    url('../../assets/fonts/PlantinMTPro-BoldItalic.woff') format('woff'),
    url('../../assets/fonts/PlantinMTPro-BoldItalic.woff2') format('woff2');
}

@font-face {
  font-family: LouizeDisplay;
  font-style: normal;
  font-weight: 700;
  fontd-display: swap;
  src:
    url('../../assets/fonts/Louize Display - Regular-205TF.woff') format('woff'),
    url('../../assets/fonts/Louize Display - Regular-205TF.woff2') format('woff2');
}

.innerHtml {
  p {
    font-size: 1.125rem;
    margin: 0;
    padding: 0;
  }

  & * {
    color: inherit;
    font-family: inherit !important;
    font-size: inherit;
  }
}

body,
body a,
body div,
body h1,
body h2,
body h3,
body h4,
body h5,
body p {
  &:not(i),
  &:not(mat-icon),
  &:not(p-button),
  &:not(.pi),
  &:not([mat-dialog-close]),
  &:not(.mat-icon),
  &:not(.p-button) {
    font-family: var(--primary-font-family);
  }
}

body .p-button:not(.font-primary),
body .p-button:not(.font-primary) .p-button-label {
  font-family: var(--secondary-font-family-condensed);
  font-weight: 400;
}

body i {
  font-family: var(--primary-italic-font-family);
}

a,
p,
span {
  text-wrap: pretty;
  word-break: break-word;
}
