@use 'abstracts/media-queries' as mq;

main.authorization-form-container {
  .authorization-form {
    margin-top: 1.5rem;
    max-width: 100%;
    text-align: center;

    .authorization-form-title {
      font-size: 2.5rem;
      line-height: var(--leading-9\.5);
      text-align: left;
      @media screen and (min-width:#{mq.$mq-sm}) {
        width: 35rem;
        word-break: break-word;
      }
    }

    .info-text {
      color: var(--gray-150);
      margin: var(--spacing-3) var(--spacing-12) var(--spacing-11) 0;
      text-align: left;
    }
  }

  .form {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-10);
    text-align: start;

    @media screen and (min-width:#{mq.$mq-sm}) {
      padding: 0 var(--spacing-4);
    }

    ol {
      display: flex;
      flex-direction: column;
      font-family: var(--secondary-font-family);
      gap: var(--spacing-10);
      padding: 0 var(--spacing-5);
      width: 100%;

      @media screen and (min-width:#{mq.$mq-sm}) {
        padding: 0 var(--spacing-8);
      }

      li {
        padding-left: var(--spacing-5);
      }
    }

    .form-field {
      border-bottom: 1px solid var(--gray-100);

      label {
        display: block;
        margin-bottom: var(--spacing-4);
      }
    }
  }

  .submit {
    align-self: flex-start;
  }

  .submitted {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 22.188rem;

    .title {
      margin-top: 1.5rem;
      text-transform: uppercase;
    }

    .subtitle {
      color: var(--gray-150);
      margin-top: 0.75rem;
    }
  }

  .form,
  .form-field {
    font-family: var(--secondary-font-family);
  }

  .grid-flow {
    display: grid;
    font-family: var(--secondary-font-family);
    gap: 3.25rem;
    grid-template-columns: 1fr;

    @media screen and (min-width:#{mq.$mq-sm}) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .info-text {
    font-weight: normal;
  }

  .authorize {
    @media screen and (min-width:#{mq.$mq-sm}) {
      padding: 0.375rem;
    }

    .checkbox {
      cursor: pointer;
      display: flex;

      input[type='checkbox'] {
        aspect-ratio: 1/1;
        background: white;
        border: 1px solid var(--gray-25);
        border-radius: 3px;
        cursor: pointer;
        display: block;
        height: 0.75rem;
        outline: 0;

        & + p {
          margin-left: var(--spacing-5);
          margin-right: var(--spacing-5);
          margin-top: 2px;

          @media screen and (min-width:#{mq.$mq-sm}) {
            margin-left: 1.625rem;
          }
        }
      }
    }
  }

  .show-tablet {
    display: none;

    @media screen and (min-width:#{mq.$mq-sm}) {
      display: block;
    }
  }
}
