@use 'abstracts/media-queries' as mq;

.__talkjs_popup {
  bottom: 3.4375rem !important;

  @media screen and (min-width:#{mq.$mq-sm}) {
    width: 31.25rem !important;
  }
}
#__talkjs_launcher {
  width: 50px;
  height: 50px;
  background-color: var(--primary-text-color);
  background-position: center;
  &:not(.open) {
    background-size: 50%;
  }
  &[data-badge] {
    &:after {
      background-color: var(--red);
      border-radius: 1.25rem;
      color: #fff;
      content: attr(data-badge);
      font-family: var(--secondary-font-family);
      font-size: 0.75rem;
      line-height: 0.625rem;
      min-height: 0.625rem;
      min-width: 0.625rem;
      padding: 0.3125rem;
      position: absolute;
      right: -0.3125rem;
      top: -0.3125rem;
    }
  }
}
