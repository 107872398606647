:root {
  // #region Font Families
  --primary-font-family: 'PlantinMTPro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --serif-headings: 'LouizeDisplay', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --primary-bold-condensed-font-family: 'PlantinMTPro-BoldCn', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --primary-italic-font-family: 'PlantinMTPro-LightItalic', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family: 'UniversLTStd', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-bold: 'UniversLTStd-Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-condensed: 'UniversLTStd-Cn', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-ultra-condensed: 'UniversLTStd-UltraCn', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --secondary-font-family-thin-ultra-condensed: 'UniversLTStd-ThinUltraCn', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --essentialist-font-family: EssenThiUltCn, Arial, sans-serif;
  // #endregion

  // #region Font Sizes
  --font-super-tiny: 0.5rem; // 8px
  --font-tiny: 0.5625rem; // 9px
  --font-xxxs: 0.625rem; // 10px
  --font-xxs: 0.6875rem; // 11px
  --font-xs: 0.75rem; // 12px
  --font-s: 0.8125rem; //13px
  --font-sm: 0.875rem; // 14px
  --font-normal: 0.9375rem; // 15px
  --font-base: 1rem; // 16px
  --font-m: 1.125rem; // 18px
  --font-l: 1.25rem; // 20px
  --font-l-0: 1.375rem; // 22px
  --font-l-1: 1.5rem; // 24px
  --font-l-2: 1.625rem; // 26px
  --font-l-3: 1.75rem; // 28px
  --font-xl: 1.875rem; // 30px
  --font-xl-2: 2.125rem; // 34px
  --font-2xl: 2.75rem; //44px
  --font-3xl: 3.75rem; // 60px
  --font-4xl: 4rem; // 64px
  --font-5xl: 5.625rem; // 90px
  --font-pre-huge: 6.625rem; // 106px
  --font-huge: 7.813rem; // 125px
  --font-super-huge: 13.375rem; // 214px
  --font-max-huge: 15.875rem; // 254px
  // #endregion

  // #region Font Weights
  --font-thin: 100;
  --font-light: 400;
  --font-regular: 600;
  --font-bold: 700;
  // #endregion

  // #region Letter Spacing
  --letter-spacing-huge-font-super-tight: -0.578rem; // -9.24px
  --letter-spacing-huge-font-tight: -0.284rem; // -4.55px
  --letter-spacing-small-font-tight: -0.075rem; // -1.02px
  --letter-spacing-tight: 0.072rem; // 1.15px
  --letter-spacing-medium: 0.086rem; // 1.38px
  --letter-spacing-normal: 0.098125rem; // 1.57px
  --letter-spacing-large: 0.106rem; // 1.69px
  --letter-spacing-xl: 0.11625rem; // 1.86px
  // #endregion
}
