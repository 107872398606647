@use '../abstracts/media-queries' as mq;

%base-icon {
  background-color: var(--primary-text-color);
  content: '';
  position: absolute;
  top: 1px;
}

%transportation-icon {
  @extend %base-icon;

  background-repeat: no-repeat;
  background-size: 12px 12px;
  background-position: center;
  border: none;
  display: block;
  height: 14px !important;
  top: 20px;
  width: 14px !important;
}

.ess-timeline {
  &::before {
    background-color: var(--gray-100);
    content: '';
    height: 100%;
    min-height: calc(100vh - 97px);
    left: 10px;
    position: absolute;
    top: 0;
    width: 1px;
  }
  > div {
    position: relative;

    &:not(:last-of-type) {
      margin-bottom: var(--spacing-5);
    }

    &:not(.alternative):not([class*='transportation'])::after {
      @extend %base-icon;
      border-radius: 50%;
      border: 1px solid var(--primary-text-color);
      height: 5px;
      left: 7px;
      width: 5px;
    }
    &.flight::after {
      @extend %transportation-icon;
      background: url('https://res.cloudinary.com/thebellhop/image/upload/v1600414896/icons/flight.svg');
      left: 3px;
      transform: rotateX(180deg);
    }
    &.boat::after,
    &.ferry::after {
      @extend %transportation-icon;
      background-color: var(--white);
      background-image: url('https://res.cloudinary.com/thebellhop/image/upload/v1600414896/icons/boat.svg');
      left: 3px;
    }
    &.walk::after {
      @extend %transportation-icon;
      background: url('https://res.cloudinary.com/thebellhop/image/upload/v1600414896/icons/walk.svg');
      left: 4px;
    }
    &.car_rental::after,
    &.drive::after {
      @extend %transportation-icon;
      background-color: var(--white);
      background-image: url('https://res.cloudinary.com/thebellhop/image/upload/v1600414896/icons/car_rental.svg');
      left: 4px;
    }
    &.bus::after {
      @extend %transportation-icon;
      background-color: var(--white);
      background-image: url('https://res.cloudinary.com/thebellhop/image/upload/v1600414896/icons/bus.svg');
      left: 4px;
    }
    &.transfer::after {
      @extend %transportation-icon;
      background-color: var(--white);
      background-image: url('https://res.cloudinary.com/thebellhop/image/upload/v1600414896/icons/transfer.svg');
      left: 4px;
    }
    &.train::after {
      @extend %transportation-icon;
      background-color: var(--white);
      background-image: url('https://res.cloudinary.com/thebellhop/image/upload/v1600414896/icons/train.svg');
      left: 4px;
    }
  }
}

@media screen and (min-width: mq.$mq-gt-lg) {
  .ess-timeline {
    &::before {
      min-height: auto;
    }

    div {
      &:not(.alternative):not([class*='transportation'])::after {
        left: -18px;
      }
      &.air-transportation::after,
      &.water-transportation::after,
      &.land-transportation::after {
        left: -21px;
      }
    }
  }
}
