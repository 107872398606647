@use 'abstracts/media-queries' as mq;
$logo-font: var(--essentialist-font-family);
$pSize: 0.875rem;
$pSizeBig: 1.5rem;
$line-height: 20px;
$z-index: 100;
$z-index-101: 101;
$z-index-max: 10000;
$cloudinary-url: 'https://res.cloudinary.com/thebellhop/image/upload/';
$container-padding: 1.563rem;
$tablet-container-padding: 2.725rem;

// #region CSS Root Variables
:root {
  // Line height
  --leading-super-tight: 0.6875rem; // 11px
  --leading-3: 0.75rem; // 12px
  --leading-3\.5: 0.8125rem; //13px
  --leading-4: 1rem; // 16px
  --leading-4\.5: 1.125rem; // 18px
  --leading-5: 1.25rem; // 20px
  --leading-body: 1.4375rem; // 23px
  --leading-6: 1.5rem; // 24px
  --leading-6\.5: 1.625rem; // 26px
  --leading-7\.5: 1.875rem; // 30px
  --leading-9: 2.25rem; // 36px
  --leading-none: 1; // 16px
  --leading-tight: 1.25; // 20px
  --leading-snug: 1.375; // 22 px
  --leading-normal: 1.5; // 24px
  --leading-relaxed: 1.625; // 26px
  --leading-loose: 2; // 32px

  // Z-index
  --z-0: 0;
  --z-1: 1;
  --z-10: 10;
  --z-100: 100;
  --z-auto: auto;

  // Spacing
  --spacing-1: 0.25rem; // 4px
  --spacing-2: 0.5rem; // 8px
  --spacing-2\.5: 0.625rem; // 10px
  --spacing-3: 0.75rem;
  --spacing-3\.5: 0.8125rem; // 13px
  --spacing-3\.75: 0.9375rem; //15px
  --spacing-4: 1rem;
  --spacing-4\.5: 1.125rem; //18px
  --spacing-5: 1.25rem; // 20px
  --spacing-5\.5: 1.5rem; // 24px
  --spacing-7: 1.75rem;
  --spacing-7\.5: 1.875rem; // 30px
  --spacing-8: 2rem;
  --spacing-9: 2.25rem;
  --spacing-9\.5: 2.375rem; // 38px
  --spacing-10: 2.5rem;
  --spacing-11: 2.75rem; // 44px
  --spacing-12: 3rem; // 48px
  --spacing-12\.5: 3.125rem; // 50px
}
// #endregion
