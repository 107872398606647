/* TODO Remove as soon as it´s been 100% validated that deeplink styles are working fine
@use 'media-queries' as mq;

$experience-bp-mobile: mq.$mq-lg; // 1024px
$experience-bp-hd: mq.$mq-hd; // 1600px
$max-width-hd: 87.5rem; // 1400px
$max-width: 90%;
$spacing: 4.375rem; //70px
$insiders_BG: #d5e7e8;
*/
