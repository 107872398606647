input,
label {
  font-family: var(--secondary-font-family);
  font-size: var(--font-normal);
}

.form-field {
  position: relative;
  width: 100%;

  &.icon-field {
    input {
      padding-right: var(--spacing-8);
    }

    i {
      position: absolute;
      right: 0;
    }
  }

  label.checkbox {
    display: flex;
    span {
      margin-left: 0.438rem;
      margin-top: 2px;
    }
  }

  input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid;
    border-color: var(--gray-25);
    padding: 0 0 var(--spacing-2) 0;
    position: relative;
    width: 100%;

    &::placeholder {
      color: var(--terciary-text-color);
    }

    &.ng-dirty.ng-invalid,
    &.error-field {
      border-bottom-color: var(--red);
      color: var(--red);

      &::placeholder {
        color: var(--red);
      }
    }
  }

  .checkbox {
    cursor: pointer;
    display: flex;

    input[type='checkbox'] {
      background: white;
      border: 1px solid var(--gray-25);
      border-radius: 0.188rem;
      cursor: pointer;
      display: block;
      height: 0.75rem;
      outline: 0;
      width: 0.75rem;

      & + span {
        margin-left: 0.438rem;
        margin-top: 2px;
      }
    }
  }

  input[type='password'] {
    letter-spacing: 0.2em;

    &::placeholder {
      letter-spacing: 0;
    }
  }

  input[type='date']::-webkit-input-placeholder {
    visibility: hidden !important;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    display: none;
  }

  .icon {
    background-color: var(--white);
    cursor: pointer;
    position: absolute;
    right: 0;
  }

  select {
    border: none;
    border-bottom: 1px solid;
    border-color: var(--gray-25);
    color: var(--primary-text-color) !important;
    font-family: var(--secondary-font-family);
    font-size: var(--font-normal) !important;
    padding: 0 0 var(--spacing-2) 0;
    text-transform: capitalize;
    width: 100%;

    &:invalid {
      color: var(--terciary-text-color) !important;
    }
  }
}

form {
  &.ng-invalid {
    div.form-field.form-error {
      input {
        border-color: var(--red);
        color: var(--red);

        &::placeholder {
          color: var(--red);
        }
      }
    }
  }
}
